<template>
  <table
    v-if="websites.length > 0"
    style="position: relative; width: 100%"
  >
    <tr
      v-for="(group, index) in groupedLoginDetails"
      :key="index"
    >
      <td
        v-for="(ld, index2) in group"
        :key="index2"
        style="padding-bottom: 25px;"
      >
        <b>{{ ld.website.name }}</b>
        <br>
        {{ ld.firstName }} {{ ld.lastName }}

        <!-- Credentials in the order as they come -->

        <!-- V-if: if website's easyId is not known in customOrder websites -->
        <div
          v-if="!customOrder.find(website => website.websiteEasyId === ld.website.easyId)"
        >
          <!-- Loop through the fields -->
          <div
            v-for="cd in ld.matchedLoginDetails.credentials"
            :key="ld._id + '-' +cd.fieldName"
          >
            <!-- Login credential (make sure it's visible) -->
            <div
              v-if="isFieldVisible(ld.website, cd.fieldName)"
            >
              {{ cd.fieldName }}: {{ cd.fieldValue }}
            </div>
          </div>
        </div>

        <!-- Else, follow the custom order -->
        <div
          v-for="fieldName in customOrder.find(website => website.websiteEasyId === ld.website.easyId).fieldNames"
          v-else
          :key="ld._id + '-' + fieldName"
        >
          <!-- Login credential (make sure it's visible) -->
          <div
            v-if="isFieldVisible(ld.website, fieldName)"
          >
            {{ fieldName }}: {{ getFieldValueFromCredentials(ld.website, ld.matchedLoginDetails.credentials, fieldName) }}
          </div>
        </div>
      </td>
    </tr>
  </table>
</template>
<script>
import axios from '@axios'

export default {
  data: () => ({
    loginDetails: [],
    websites: [],
    customOrder: [
      {
        websiteEasyId: 'scoodle-play',
        fieldNames: [
          'schoolcode',
          'klasnaam',
          'klasnummer',
          'wachtwoord',
        ],
      },
    ],
  }),
  computed: {
    groupedLoginDetails() {
      const groups = []

      this.loginDetails.forEach((item, index) => {
        const groupIndex = Math.floor(index / 3)

        if (!groups[groupIndex]) {
          groups[groupIndex] = []
        }

        groups[groupIndex].push(item)
      })

      return groups
    },
  },

  mounted() {
    document.title = 'Labels afdrukken - BlokPass'
    const school = JSON.parse(localStorage.getItem('school'))
    this.ldIds = JSON.parse(localStorage.getItem('ldIds')) || []
    this.selectedWebsites = JSON.parse(localStorage.getItem('selectedWebsites')) || []
    axios.get('/website')
      .then(res => {
        this.websites = res.data.websites
        console.log('websites')
        console.log(this.websites)
        axios.put(`/school/${school.id}/logindetails`, {
          accountIds: this.ldIds,
        })
          .then(resp => {
            this.loginDetails = resp.data.accounts.map(account => {
              const acc = account
              acc.website = this.websites.find(w => w.id === account.matchedLoginDetails.website)
              if (this.selectedWebsites.includes(acc.website.easyId)) {
                return acc
              }

              console.log(`${acc.website.easyId} is not selected`)

              return null
            })
            this.loginDetails = this.loginDetails.filter(ld => ld != null)
            setTimeout(() => {
              window.print()
            }, 2000)
          })
      })

    setTimeout(() => {
      // window.print()
    }, 1000)
  },

  methods: {
    isFieldVisible(website, fieldName) {
      if (!website.hiddenFieldsOnLabels) return true

      return !website.hiddenFieldsOnLabels.includes(fieldName)
    },

    // If the field is the 'klasnaam' for the 'scoodle' website, add an 'L' to it on the labels.
    getFieldValueFromCredentials(website, credentials, fieldName) {
      const field = credentials.find(c => c.fieldName === fieldName)
      if (field) {
        if (website.easyId === 'scoodle-play' && fieldName === 'klasnaam') {
          return `L${field.fieldValue}`
        }

        return field.fieldValue
      }

      return ''
    },
  },
}
</script>

<style>
.theme--light.v-application {
  background: white;
}
img {
  max-width: 100%;
  height: auto;
  width: 80%;
  margin: auto;
}

.name {
  font-size: large;
  color: black;
}

tr {
  page-break-inside: avoid;
}

body {
    background-color: white;
    margin: 20px;
}

table {
    width: 100%;
    position: relative;
    border-collapse: separate;
    border-spacing: 0, 15px;
}

td {
    vertical-align: top;
    width: 33%;
    padding-bottom: 20px;
    font-size: 20px;
}

.pageBreakAfter {
    page-break-after: always;
}
</style>
